@font-face {
  font-family: Cereal Black;
  src: url("./Assets/fonts/AirbnbCerealApp-Black.ttf");
}

@font-face {
  font-family: Cereal Extrabold;
  src: url("./Assets/fonts/AirbnbCerealApp-ExtraBold.ttf");
}

@font-face {
  font-family: Cereal Bold;
  src: url("./Assets/fonts/AirbnbCerealApp-Bold.ttf");
}

@font-face {
  font-family: Cereal Medium;
  src: url("./Assets/fonts/AirbnbCerealApp-Medium.ttf");
}

@font-face {
  font-family: Cereal;
  src: url("./Assets/fonts/AirbnbCerealApp-Book.ttf");
}

@font-face {
  font-family: Cereal Light;
  src: url("./Assets/fonts/AirbnbCerealApp-Light.ttf");
}

@font-face {
  font-family: "Matter SQ Regular";
  src: url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.eot");
  src: url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/8611cf274fd66935b8f771ac84029521.svg#Matter SQ Regular") format("svg");
}

@font-face {
  font-family: "Matter SQ Medium";
  src: url("https://db.onlinewebfonts.com/t/367cb1d4d8a017fec88cbbcf75d6b3cc.eot");
  src: url("https://db.onlinewebfonts.com/t/367cb1d4d8a017fec88cbbcf75d6b3cc.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/367cb1d4d8a017fec88cbbcf75d6b3cc.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/367cb1d4d8a017fec88cbbcf75d6b3cc.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/367cb1d4d8a017fec88cbbcf75d6b3cc.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/367cb1d4d8a017fec88cbbcf75d6b3cc.svg#Matter SQ Medium") format("svg");
}

@font-face {
  font-family: "Neue Haas Grotesk Display Pro";
  src: url("https://db.onlinewebfonts.com/t/5d010dbef0d6f2fd2cad43b2b78a3554.eot");
  src: url("https://db.onlinewebfonts.com/t/5d010dbef0d6f2fd2cad43b2b78a3554.eot?#iefix") format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5d010dbef0d6f2fd2cad43b2b78a3554.woff2") format("woff2"),
    url("https://db.onlinewebfonts.com/t/5d010dbef0d6f2fd2cad43b2b78a3554.woff") format("woff"),
    url("https://db.onlinewebfonts.com/t/5d010dbef0d6f2fd2cad43b2b78a3554.ttf") format("truetype"),
    url("https://db.onlinewebfonts.com/t/5d010dbef0d6f2fd2cad43b2b78a3554.svg#Neue Haas Grotesk Display Pro")
      format("svg");
}

@font-face {
  font-family: "Neue Haas Display";
  src: url("./Assets/fonts/NeueHaasDisplayRoman.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Neue Haas Display";
  src: url("./Assets/fonts/NeueHaasDisplayMediu.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Neue Haas Display";
  src: url("./Assets/fonts/NeueHaasDisplayBold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Neue Haas Display";
  src: url("./Assets/fonts/NeueHaasDisplayBold.ttf");
  font-weight: 700;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-height: 1000px) {
  .screen {
    display: flex;
    align-items: center;
    height: 100vh;
  }
}

* {
  font-family: Cereal;
}

.vertical_scroll {
  overflow-y: scroll !important;
}

.otp {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  align-items: center;
}


a,button {
  font-family: inherit;
}

input {
  font-size: 1rem;
}

.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
}

.dashboard-content {
  height: calc(100vh - 80px);
}

.settings-content {
  height: calc(100vh - 80px);
  width: calc(100vw - 300px);
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.chat-ticket-content {
  height: calc(100vh - 310px);
}

.chat-width {
  width: calc(100vw - 760px);
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.user-content {
  height: calc(100vh - 150px);
}

.chat-content {
  height: calc(100vh - 120px);
}

.chat-height {
  height: calc(100vh - 270px);
}

.subscription,
.scroll-y {
  overflow-y: scroll;
}

.scroll-x {
  overflow-x: scroll;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
  background-color: #fcfcfd;

  &:last-child {
    border-top-right-radius: 10px;
  }

  &:first-child {
    border-top-left-radius: 10px;
  }
}

.ant-drawer .ant-drawer-body {
  padding: 0 !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  padding-bottom: 2rem;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero_bg {
  background: url("./Assets/images/about_banner_1.svg");
  height: 600px;
  background-repeat: no-repeat;
  @media (min-width: 768px) and (max-width: 1023.98px) {
    height: 300px;
    background-size: cover;
  }
  @media (min-width: 1024px) and (max-width: 1399.98px) {
    height: 350px;
    background-size: contain;
  }
  @media (min-width: 1500px) {
    background-size: cover;
  }
}

.camera-container {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 20px auto;
  overflow: hidden;
}

/* .webcam {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
} */

.circular-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  border-radius: 10px;
  box-shadow: 0 0 0 100vmax rgba(15, 23, 42, 0.6);
}

/* Spinner Circle Rotation */
.customLoadingSpinner {
	border: 10px #0166FF solid;
	border-top: 10px #E9ECEF solid;
	border-radius: 50%;
	-webkit-animation: spCircRot .9s infinite linear;
	animation: spCircRot .9s infinite linear;
}
@-webkit-keyframes spCircRot {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(359deg); }
}
@keyframes spCircRot {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}
